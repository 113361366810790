import { GetProductOffersResponse } from '@iwoca/lapi-client/edge';

import { RecursiveNonNullable } from '../../utils/typeUtils';
import { TSellerProduct } from '../PayLinkLanding/utils/PayLinkLanding.types';

type TProductOfferDuration =
  RecursiveNonNullable<GetProductOffersResponse>['data']['products'][number]['offers'][number]['duration'];

export const displayTextFromProduct = {
  THREE_MONTHS: '3 months',
  TWELVE_MONTHS: '12 months',
  THIRTY_DAYS: '30 days',
} as const;

export const productFromDuration = {
  1: 'THIRTY_DAYS',
  3: 'THREE_MONTHS',
  12: 'TWELVE_MONTHS',
} as const;

export const durationFromPricingOption = {
  payLater3: 3,
  payLater12: 12,
  payLater1: 1,
  payNow: null,
} as const;

export function getDurationInMonths(duration: TProductOfferDuration) {
  if (duration.unit === 'days') {
    return Math.floor(duration.amount / 30);
  }

  if (duration.unit === 'months') {
    return duration.amount;
  }

  if (duration.unit === 'years') {
    return Math.floor(duration.amount * 12);
  }

  throw new Error('Invalid duration unit defined');
}

export type SuggestedProductType = ReturnType<typeof getSuggestedProduct>;

export function getSuggestedProduct(productOffers: GetProductOffersResponse) {
  const suggestedProduct = productOffers?.data?.products?.find(
    (product) => product.is_suggested === true,
  );

  return suggestedProduct;
}
