import React from 'react';

import {
  fetchGetApplication,
  fetchGetStateByStateKey,
  fetchGetIwocapayPaymentLater,
} from '@iwoca/lapi-client/edge';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router';

import { DecisionModal } from './components/DecisionModal/DecisionModal';
import { DecisionProvider } from './DecisionProvider';
import { SignupLayout } from './SignupLayout';
import { StepValidator } from './steps/StepValidator';
import { LoadingScreen } from '../../components/LoadingScreen/LoadingScreen';
import { useStateKey } from '../../hooks/useStateKey.hook';
import { useThreatMetrix } from '../../utils/ThreatMetrix';
import { LoadingCard } from '../components/LoadingCard/LoadingCard';
import { ModalProvider } from '../store/ModalProvider';
import { buildQueryString } from '../utils/queryParams';

export const Signup = ({ children }: { children: React.ReactNode }) => {
  const { stateKey } = useStateKey();
  const [, setSearchParams] = useSearchParams();

  const query = useQueries({
    queries: [
      {
        queryKey: ['fetchGetApplication'],
        queryFn: () =>
          fetchGetApplication({
            stateKey: stateKey!,
          }),
        enabled: Boolean(stateKey),
      },
      {
        queryKey: ['fetchGetState'],
        queryFn: () =>
          fetchGetStateByStateKey({
            stateKey: stateKey!,
          }),
        enabled: Boolean(stateKey),
      },
    ],
  });

  const { isLoading: isLoadingPaylink } = useQuery({
    queryKey: ['fetchGetIwocapayPaymentLater'],
    queryFn: async () => {
      const { data } = await fetchGetIwocapayPaymentLater({
        stateKey: stateKey!,
      });

      const payLinkId = data[0].id;
      const searchParams = buildQueryString({ payLinkId }, true);
      setSearchParams(searchParams);

      return { data };
    },
    enabled: Boolean(stateKey),
  });

  useThreatMetrix();

  if (isLoadingPaylink) return <LoadingScreen />;

  const hasLoaded = query.every(
    ({ status, data }) => data && status === 'success',
  );

  return (
    <StepValidator>
      <ModalProvider>
        <DecisionProvider>
          <SignupLayout>
            {hasLoaded ? (
              <>{children}</>
            ) : (
              <>
                <LoadingCard />
                <LoadingCard />
                <LoadingCard />
              </>
            )}
          </SignupLayout>
          <DecisionModal />
        </DecisionProvider>
      </ModalProvider>
    </StepValidator>
  );
};
