import { useCurrentAmount } from './useCurrentAmount';
import { useGetValidIwocapayFormalOffers } from './useGetValidIwocapayFormalOffers';
import {
  durationFromPricingOption,
  getDurationInMonths,
} from '../../utils/product';
import {
  MAX_AMOUNT_PAY_LATER,
  MIN_AMOUNT_PAY_LATER,
} from '../PaymentDetails/PaymentDetails';
import { TPricingOption } from '../utils/PayLinkLanding.types';

export const useHasInsufficientCredit = ({
  pricingOption,
}: {
  pricingOption: TPricingOption;
}) => {
  const { formalOffers, loadingFormalOffers } =
    useGetValidIwocapayFormalOffers();
  const amount = useCurrentAmount();
  const duration = durationFromPricingOption[pricingOption];

  // Only consider valid pay later amounts
  if (
    !amount ||
    parseFloat(amount) < MIN_AMOUNT_PAY_LATER ||
    parseFloat(amount) > MAX_AMOUNT_PAY_LATER
  )
    return {
      hasInsufficientCredit: false,
      loadingHasInsufficientCredit: loadingFormalOffers,
    };

  const availableCredit = formalOffers.find(
    (formalOffer) =>
      formalOffer.duration &&
      getDurationInMonths(formalOffer.duration) === duration,
  )?.available_credit;

  if (availableCredit !== undefined && availableCredit < parseFloat(amount))
    return {
      hasInsufficientCredit: true,
      loadingHasInsufficientCredit: loadingFormalOffers,
    };

  return {
    hasInsufficientCredit: false,
    loadingHasInsufficientCredit: loadingFormalOffers,
  };
};
