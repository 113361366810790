import { GetExampleRepaymentScheduleResponse } from '@iwoca/lapi-client/edge';

import { TDuration } from '../Buyer/PayLinkLanding/utils/PayLinkLanding.types';
import { productFromDuration } from '../Buyer/utils/product';
import { lendingApiFetchJson } from '../Pages/lendingApiFetch';

export type ExampleRepaymentScheduleOverview = {
  schedule_id: string;
  product: (typeof productFromDuration)[keyof typeof productFromDuration];
  totalInterest: number;
  exampleMonthlyRepayment: number;
  exampleTotalRepayment: number;
  fullSchedule: GetExampleRepaymentScheduleResponse['data'];
};

export const getExampleRepaymentScheduleOverview = async (
  amount: number,
  representativeInterestRate: number,
  promotions: string[],
  duration: TDuration,
): Promise<ExampleRepaymentScheduleOverview> => {
  const queryString = new URLSearchParams({
    '30_day_rate': representativeInterestRate.toString(),
    initial_principal: amount.toString(),
    loan_duration_months: duration.toString(),
    product: 'iwocapay',
    promotions: promotions.join(',') || 'equal_repayments',
  });

  const exampleRepaymentScheduleResponse = await lendingApiFetchJson(
    `/api/lending/edge/example_repayment_schedule/?${queryString}`,
  );

  if (!exampleRepaymentScheduleResponse.ok) {
    throw new Error('Could not get example repayment schedule');
  }

  const exampleRepaymentSchedule: GetExampleRepaymentScheduleResponse =
    await exampleRepaymentScheduleResponse.json();

  return {
    schedule_id: `${amount}_${duration}`,
    product: productFromDuration[duration],
    ...formatExampleRepaymentSchedule(exampleRepaymentSchedule),
  };
};

const formatExampleRepaymentSchedule = (
  exampleRepaymentSchedulePayload: GetExampleRepaymentScheduleResponse,
) => {
  let totalInterest = 0.0;
  let exampleTotalRepayment = 0.0;
  let exampleMonthlyRepayment = 0.0;

  if (
    exampleRepaymentSchedulePayload.data &&
    exampleRepaymentSchedulePayload.data.length > 0
  ) {
    exampleMonthlyRepayment = exampleRepaymentSchedulePayload.data[0].total;

    for (const element of exampleRepaymentSchedulePayload.data) {
      totalInterest += element.interest;
      exampleTotalRepayment += element.total;
    }
  }

  return {
    totalInterest,
    exampleMonthlyRepayment: exampleMonthlyRepayment
      ? exampleMonthlyRepayment
      : 0,
    exampleTotalRepayment: Math.round(exampleTotalRepayment * 100) / 100,
    fullSchedule: exampleRepaymentSchedulePayload.data,
  };
};
