type TPrimitive = string | number | symbol;
export function lookup<
  GKey extends TPrimitive | null | undefined,
  GMap extends Record<TPrimitive, TPrimitive>,
>(key: GKey, map: GMap): GKey extends keyof GMap ? GMap[GKey] : undefined {
  if (!key) {
    // @ts-expect-error - This is fine
    return;
  }
  if (!(key in map)) {
    // @ts-expect-error - This is fine
    return;
  }
  // @ts-expect-error - This is fine
  return map[key as keyof GMap] as unknown as GMap[GKey];
}
