import { fetchPostQuickbooksDisconnect } from '@iwoca/lapi-client/edge';
import { AlertBox, Button } from '@iwoca/orion';
import cn from 'classnames';
import { useParams, useSearchParams } from 'react-router';

import styles from './Quickbooks.module.css';
import { useGetSellerConnection } from '../../../../api/lending/lapiHooks';
import externalLinkSVG from '../../../../assets/externalLink.svg';
import { useStateKey } from '../../../../hooks/useStateKey.hook';
import exclamationTriangle from '../../assets/exclamationTriangle.svg';
import tickSVG from '../../assets/tick.svg';
import { useGetIntegrationStatus } from '../../hooks/useGetIntegrationStatus.hook';
import { QuickBooks } from '../../Integrations';
import { ConnectButton } from '../ConnectButton/ConnectButton';
import bodyStyles from '../IntegrationBody.module.css';
import { IntegrationsTitle } from '../IntegrationsTitle/IntegrationsTitle';

export function QuickBooksBody() {
  const { integrationParam } = useParams();
  const { stateKey } = useStateKey();
  const [searchParams] = useSearchParams();
  const { integrationStatus, loadingIwocapaySellerConnection } =
    useGetIntegrationStatus({
      integrationId: QuickBooks.id,
    });
  const isConnected = integrationStatus === 'CONNECTED';

  const connectionSuccess = searchParams.get('connection_success');

  if (integrationParam !== QuickBooks.id) {
    return null;
  }
  return (
    <div className={bodyStyles.container}>
      {connectionSuccess === 'false' && (
        <AlertBox className={bodyStyles.alertBox} variant="failure">
          <img
            src={exclamationTriangle}
            alt="Warning Icon"
            className={bodyStyles.alertSVG}
          />
          Something went wrong when trying to connect QuickBooks. Please try
          again.
        </AlertBox>
      )}
      <div className={bodyStyles.header}>
        <IntegrationsTitle integration={QuickBooks} />
        <div
          className={cn(bodyStyles.connectButtonWrapper, {
            [bodyStyles.connectButtonWrapperConnected]: isConnected,
          })}
        >
          {isConnected && !loadingIwocapaySellerConnection && (
            <div className={bodyStyles.isConnected}>
              <img
                src={tickSVG}
                alt="Tick Icon"
                className={bodyStyles.tickSVG}
              />
              Connected
            </div>
          )}
          {!loadingIwocapaySellerConnection &&
            (isConnected ? (
              <DisconnectButton />
            ) : (
              <ConnectButton
                link={`/api/lending/edge/quickbooks/authorization_redirect/${stateKey}/`}
                isExternal={true}
                isConnected={false}
              >
                Connect
              </ConnectButton>
            ))}
        </div>
      </div>
      <div className={bodyStyles.body}>
        <p>
          Simple, smart accounting software that helps you to take control of
          your finances. From invoicing and expenses to payroll and submitting
          VAT.
        </p>
        <h3 className={bodyStyles.subHeading}>
          When you connect iwocaPay to Quickbooks it'll automatically:
        </h3>
        <ul className={bodyStyles.bulletList}>
          <li>
            add iwocaPay as a payment method on invoices sent from Quickbooks
          </li>
          <li>reconcile all iwocaPay payments with your Quickbooks.</li>
        </ul>
        <a
          href="https://ecommerce-api.iwoca.com/guides/plugin-solution/quickbooks/"
          target="_blank"
          rel="noreferrer"
          className={bodyStyles.faqLink}
        >
          Quickbooks FAQs
          <img
            src={externalLinkSVG}
            alt="External Link Icon"
            className={bodyStyles.externalLinkIcon}
          />
        </a>
      </div>
      <div className={bodyStyles.footer}>
        {!loadingIwocapaySellerConnection &&
          (isConnected ? (
            <DisconnectButton />
          ) : (
            <ConnectButton
              link={`/api/lending/edge/quickbooks/authorization_redirect/${stateKey}/`}
              isExternal={true}
              isConnected={false}
            >
              Connect
            </ConnectButton>
          ))}
      </div>
    </div>
  );
}

const DisconnectButton = () => {
  const { stateKey } = useStateKey();
  const { fetchIwocapaySellerConnection, loadingIwocapaySellerConnection } =
    useGetSellerConnection();

  return (
    <Button
      variant="secondary"
      className={styles.disabledButton}
      disabled={loadingIwocapaySellerConnection}
      onClick={async () => {
        await fetchPostQuickbooksDisconnect({ stateKey: stateKey! });
        await fetchIwocapaySellerConnection();
      }}
    >
      Disconnect
    </Button>
  );
};
