import { useMemo, useState } from 'react';

import { fetchPostIwocapayPaymentLater } from '@iwoca/lapi-client/edge';
import { fetchPostSharedUserAction } from '@iwoca/lapi-client/iwocapay';
import { useSearchParams } from 'react-router';

import styles from './GenericPayLinkLanding.module.css';
import {
  SELLER_GET_ERROR,
  useGetIwocapayBuyerPayLinkPackage,
  useGetProfile,
  useGetSellerByHandle,
} from '../../../api/lending/lapiHooks';
import { LoadingScreen } from '../../../components/LoadingScreen/LoadingScreen';
import { useQueryParam } from '../../../hooks/useQueryParam';
import { GenericError } from '../../../Pages/FailurePages/GenericError';
import { createBuyerAccount } from '../../../Pages/stateApi';
import { FormalOffer } from '../../../Seller/PayLinks/utils/Paylinks.types';
import { lookup } from '../../../utils/Lookup';
import { useThreatMetrix } from '../../../utils/ThreatMetrix';
import {
  trackSelectedCheckoutOption,
  trackSelectedOffer,
} from '../../../utils/tracking';
import { LoginBanner } from '../../components/LoginBanner';
import {
  TEnabledOptions,
  useEnabledOptions,
} from '../../hooks/useEnabledTerms';
import { useIsOverlay } from '../../hooks/useIsOverlay';
import { useTrackLandingPageView } from '../../hooks/useTrackLandingPageView';
import { LoginPanel } from '../../LoginPanel/LoginPanel';
import {
  payNowCheckoutPayLinkPackage,
  payNowCheckoutUniversalLink,
} from '../../PayNow/checkout';
import { SIGNUP_BASE_PATH } from '../../Signup/routes';
import { createState } from '../../utils/customerState';
import { buildQueryString } from '../../utils/queryParams';
import { HelpTextAccordion } from '../HelpTextAccordion/HelpTextAccordion';
import { useGetValidIwocapayFormalOffers } from '../hooks/useGetValidIwocapayFormalOffers';
import { PaymentDetails } from '../PaymentDetails/PaymentDetails';
import { FormValues } from '../utils/PayLinkLanding.types';
import {
  getBuyerInterest,
  getPricingPromotions,
  getRelevantFormalOfferId,
  shouldRedirectToCheckout,
} from '../utils/productPricingHelpers';

export const GenericPayLinkLanding = ({
  sellerHandle,
  payLinkPackageId,
}: {
  sellerHandle: string;
  payLinkPackageId?: string;
}) => {
  const { sellerError, seller, loadingSeller } =
    useGetSellerByHandle(sellerHandle);

  const amount = useQueryParam('amount');
  const reference = useQueryParam('reference');

  const { formalOffers, loadingFormalOffers } =
    useGetValidIwocapayFormalOffers();

  const { payLinkPackage, loadingPayLinkPackage } =
    usePayLinkPackage(payLinkPackageId);
  const { enabledOptions, loadingOptions } = useEnabledOptions({
    pricing: payLinkPackage?.product_pricing || seller?.product_pricing,
  });

  const isOverlay = useIsOverlay();

  const queryParamAmount = parseAmountFromQueryParam(amount);

  const {
    profile: { email_address },
  } = useGetProfile();

  // User defined state of the form saved in the URL, back up for page refreshes or navigation
  const [searchParams, setSearchParams] = useSearchParams();
  const getStateFromUrl = () => {
    const encodedUserState = searchParams.get('state');
    const userState = encodedUserState
      ? JSON.parse(atob(encodedUserState))
      : {};
    return userState;
  };

  const setUrlState = (key: string, value: string) => {
    const newUserState = getStateFromUrl();
    if (value) {
      newUserState[key] = value;
    } else {
      delete newUserState[key];
    }
    const encodedUserState = btoa(JSON.stringify(newUserState));
    searchParams.set('state', encodedUserState);

    setSearchParams(searchParams, { replace: true });
  };

  const initialUrlState = useMemo(
    () => getStateFromUrl(),

    // use memo here is needed to stop the form provider loosing context and dropping the selected product.
    // we do need it to recalculate when logging in hence the dep array. otherwise it would revert to the initial values the page loaded with
    // we must use email_address over profile as profile changes in memory a lot but the string comparison is stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email_address],
  );

  const initialValues = {
    amount: (
      payLinkPackage?.amount ||
      queryParamAmount ||
      initialUrlState.amount ||
      ''
    ).toString(),
    reference:
      payLinkPackage?.reference || reference || initialUrlState.reference || '',
  };

  const [loginPanelOpen, setLoginPanelOpen] = useState(false);

  useTrackLandingPageView({
    payLinkType: 'generic',
    visibleOptions: enabledOptions,
    productPricing: payLinkPackage
      ? payLinkPackage.product_pricing
      : seller?.product_pricing,
    sellerId: seller?.seller_id,
    loadingApis: loadingPayLinkPackage || loadingSeller,
  });

  useThreatMetrix();

  if (sellerError) {
    return <GenericError errorText={sellerError as string} />;
  }

  if (!seller || loadingSeller || loadingOptions || loadingFormalOffers) {
    return <LoadingScreen />;
  }

  if (payLinkPackage?.deleted_at) {
    return <GenericError errorText={SELLER_GET_ERROR} />;
  }

  const productPricing = payLinkPackage
    ? payLinkPackage.product_pricing
    : seller.product_pricing;

  const buyerInterest = getBuyerInterest({
    productPricing,
    formalOffers: formalOffers,
  });

  const pricingPromotions = getPricingPromotions({
    productPricing,
  });

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.paymentDetailsColumn}>
            <LoginBanner onClick={() => setLoginPanelOpen(true)} />
            <PaymentDetails
              isPreview={false}
              sellerName={seller.trading_name}
              pricingPromotions={pricingPromotions}
              buyerInterest={buyerInterest}
              onSubmit={onSubmit({
                sellerHandle,
                payLinkPackageId,
                formalOffers,
                enabledOptions,
                isOverlay,
              })}
              initialValues={initialValues}
              setUrlState={setUrlState}
              lockAmount={Boolean(payLinkPackage?.amount || queryParamAmount)}
              lockReference={Boolean(payLinkPackage?.reference || reference)}
              enabledOptions={enabledOptions}
              payLinkType="generic"
            />
            <div className="pt-xl text-center text-m font-reg text-black">
              Find out how we use your data in our&nbsp;
              <a
                href="/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>

          <div className={styles.helpAccordionColumn}>
            <HelpTextAccordion
              className={styles.helpAccordion}
              enabledOptions={enabledOptions}
            />
          </div>
        </div>
      </div>
      <LoginPanel
        open={loginPanelOpen}
        close={() => setLoginPanelOpen(false)}
      />
    </div>
  );
};

const onSubmit = ({
  sellerHandle,
  payLinkPackageId,
  formalOffers,
  enabledOptions,
  isOverlay,
}: {
  sellerHandle: string;
  payLinkPackageId?: string;
  formalOffers?: FormalOffer[];
  enabledOptions: TEnabledOptions;
  isOverlay: boolean;
}) => {
  return async ({
    values,
    stateKey,
  }: {
    values: FormValues;
    stateKey?: string;
  }) => {
    const {
      paymentOption,
      reference,
      payNowFullName,
      payNowEmailAddress,
      payNowMarketingConsent: marketingConsent,
    } = values;
    const amount = parseFloat(values.amount);

    if (paymentOption === 'payNow') {
      const customerDetails = {
        emailAddress: payNowEmailAddress,
        fullName: payNowFullName,
      };

      if (payLinkPackageId) {
        return payNowCheckoutPayLinkPackage({
          payLinkPackageId,
          customerDetails,
          marketingConsent,
          isOverlay,
        });
      }

      return payNowCheckoutUniversalLink({
        sellerHandle,
        payLinkDetails: {
          amount,
          reference,
        },
        customerDetails,
        marketingConsent,
        isOverlay,
      });
    } else {
      const PAYMENT_OPTION_TO_SELECTED_DURATION = {
        payLater3: 'THREE_MONTHS',
        payLater12: 'TWELVE_MONTHS',
        payLater1: 'THIRTY_DAYS',
      } as const;

      const selectedOption =
        lookup(paymentOption, PAYMENT_OPTION_TO_SELECTED_DURATION) ||
        PAYMENT_OPTION_TO_SELECTED_DURATION.payLater3;

      const payLaterRequestBody = {
        data: {
          amount,
          reference,
          seller_handle: sellerHandle,
          pay_link_package_id: payLinkPackageId,
          requested_duration: selectedOption,
        },
      };

      if (stateKey) {
        const payLinkResponse = await fetchPostIwocapayPaymentLater({
          stateKey,
          body: payLaterRequestBody,
        });
        const payLink = await payLinkResponse.json();

        trackSelectedCheckoutOption({
          payLinkId: payLink.data.id as string,
          visibleOptions: enabledOptions,
          selectedOption: selectedOption,
        });

        await fetchPostSharedUserAction({
          stateKey,
          body: { user_action: 'CHECKOUT_PAY_LINK' },
        });

        if (
          shouldRedirectToCheckout({
            paymentOption,
            payLinkAmount: amount,
            formalOffers,
          })
        ) {
          const offerId = getRelevantFormalOfferId({
            paymentOption,
            formalOffers,
          });
          if (offerId) {
            trackSelectedOffer({
              payLinkId: payLink.data.id as string,
              offerIds: formalOffers!.map((offer) => offer.offer_id!),
              offerId: offerId,
            });

            window.location.href = `/pay/checkout/offer/${buildQueryString({
              payLinkId: payLink.data.id,
              ...(offerId && { offerId: offerId }),
            })}`;
            return;
          }
        }
        window.location.href = `/account/iwocapay/${buildQueryString({
          source: 'iwocapay-pay-link',
        })}`;
      } else {
        const { emailAddress, marketingOptIn } = values;
        const customerState = await createBuyerAccount(
          createState(emailAddress, marketingOptIn),
        );

        const payLinkResponse = await fetchPostIwocapayPaymentLater({
          stateKey: customerState.data!.state_key,
          body: payLaterRequestBody,
        });
        const payLink = await payLinkResponse.json();

        trackSelectedCheckoutOption({
          payLinkId: payLink.data.id as string,
          visibleOptions: enabledOptions,
          selectedOption: selectedOption,
        });

        await fetchPostSharedUserAction({
          stateKey: customerState.data!.state_key,
          body: { user_action: 'CHECKOUT_PAY_LINK' },
        });

        window.location.href = SIGNUP_BASE_PATH + buildQueryString();
      }
    }
  };
};

function usePayLinkPackage(payLinkPackageId?: string) {
  const { iwocapayBuyerPayLinkPackage: payLinkPackage, loading } =
    useGetIwocapayBuyerPayLinkPackage({
      payLinkPackageId,
      enabled: !!payLinkPackageId,
    });

  return {
    payLinkPackage,
    loadingPayLinkPackage: loading,
  };
}

function parseAmountFromQueryParam(amount: string | null) {
  if (!amount) return;

  const sanitisedString = amount.replace(',', '');
  const parsedAmount = parseFloat(sanitisedString);
  if (isNaN(parsedAmount)) return;

  return parsedAmount.toFixed(2);
}
