import {
  GetEcommerceOrderResponse,
  GetPayLinkResponse,
} from '@iwoca/lapi-client/edge';

import { useQueryParam } from '../../hooks/useQueryParam';
import { useStateKey } from '../../hooks/useStateKey.hook';
import { lookup } from '../../utils/Lookup';

type ProductPricing = NonNullable<GetPayLinkResponse['product_pricing']>;

export type TEnabledOptions = (
  | 'THREE_MONTHS'
  | 'TWELVE_MONTHS'
  | 'THIRTY_DAYS'
  | 'PAY_NOW'
)[];

const DURATION_TO_TERM = {
  1: 'THIRTY_DAYS',
  3: 'THREE_MONTHS',
  12: 'TWELVE_MONTHS',
} as const;

type TTerms = (typeof DURATION_TO_TERM)[keyof typeof DURATION_TO_TERM][];

export const mapDurationToTerm = (duration?: keyof typeof DURATION_TO_TERM) => {
  return lookup(duration, DURATION_TO_TERM);
};

const mapPricingEnabledTerms = ({ pricing }: { pricing: ProductPricing }) => {
  return Object.keys(pricing) as TTerms;
};

const useEnabledTerms = ({ pricing }: { pricing?: ProductPricing }) => {
  // If a spending limit is in place, reuse that spending limit duration
  // Only validate this if a state_key is present
  const { loadingStateKey } = useStateKey();
  // We need to wait for the site context to be loaded or rejected
  // formal offers is only going to be in the "loading" state
  // if a statekey exists.

  if (loadingStateKey) return { enabledTerms: [], loading: true };

  if (pricing) {
    return {
      enabledTerms: mapPricingEnabledTerms({ pricing }),
      loading: false,
    };
  }

  return { enabledTerms: [], loading: false };
};

export const useEnabledOptions = ({
  allowedOptions,
  pricing,
}: {
  allowedOptions?: GetEcommerceOrderResponse['data']['allowed_payment_terms'];
  pricing?: ProductPricing;
}): { enabledOptions: TEnabledOptions; loadingOptions: boolean } => {
  const { enabledTerms, loading } = useEnabledTerms({ pricing });
  const paymentMode = useQueryParam('payment_mode')?.toUpperCase();

  if (loading)
    return {
      enabledOptions: [],
      loadingOptions: true,
    };

  if (paymentMode === 'PL')
    return {
      enabledOptions: enabledTerms,
      loadingOptions: false,
    };

  if (allowedOptions) {
    if (!allowedOptions.includes('PAY_NOW'))
      return { enabledOptions: enabledTerms, loadingOptions: false };
    if (!allowedOptions.includes('PAY_LATER'))
      return { enabledOptions: ['PAY_NOW'], loadingOptions: false };
  }

  return {
    enabledOptions: [...enabledTerms, 'PAY_NOW'],
    loadingOptions: false,
  };
};
