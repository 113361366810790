import { useEffect, useState } from 'react';

import {
  GetEcommerceOrderResponse as Order,
  GetPayLinkResponse as PayLink,
} from '@iwoca/lapi-client/edge';
import { fetchPostSharedUserAction } from '@iwoca/lapi-client/iwocapay';
import { useParams } from 'react-router';

import styles from './OrderCheckoutPage.module.css';
import { RequestedDuration } from '../../../api/lending/edge';
import {
  useGetEcommerceOrder,
  useGetPayLinkByPayLinkId,
  useGetSellerByHandle,
} from '../../../api/lending/lapiHooks';
import { LoadingScreen } from '../../../components/LoadingScreen/LoadingScreen';
import { OrderLinkIsNotValid } from '../../../Pages/FailurePages/OrderLinkIsNotValid';
import { OrderNotFound } from '../../../Pages/FailurePages/OrderNotFound';
import { PayLinkNotFound } from '../../../Pages/FailurePages/PayLinkNotFound';
import { FormalOffer } from '../../../Seller/PayLinks/utils/Paylinks.types';
import { useSkipEcomLanding } from '../../../SplitTest/useSkipEcomLanding';
import { useThreatMetrix } from '../../../utils/ThreatMetrix';
import {
  trackSelectedCheckoutOption,
  trackSelectedOffer,
} from '../../../utils/tracking';
import { CancelButton } from '../../components/CancelButton/CancelButton';
import { LoginBanner } from '../../components/LoginBanner';
import {
  TEnabledOptions,
  useEnabledOptions,
} from '../../hooks/useEnabledTerms';
import { useIsOverlay } from '../../hooks/useIsOverlay';
import { useTrackLandingPageView } from '../../hooks/useTrackLandingPageView';
import { LoginPanel } from '../../LoginPanel/LoginPanel';
import { HelpTextAccordion } from '../../PayLinkLanding/HelpTextAccordion/HelpTextAccordion';
import { useGetValidIwocapayFormalOffers } from '../../PayLinkLanding/hooks/useGetValidIwocapayFormalOffers';
import {
  submitBuyerStepZeroNewCustomer,
  submitBuyerStepZeroReturningCustomer,
} from '../../PayLinkLanding/PayLater/CreateUserForm/submitBuyerStepZero';
import { PaymentDetails } from '../../PayLinkLanding/PaymentDetails/PaymentDetails';
import { FormValues } from '../../PayLinkLanding/utils/PayLinkLanding.types';
import {
  getBuyerInterest,
  getPricingPromotions,
  getRelevantFormalOfferId,
  shouldRedirectToCheckout,
} from '../../PayLinkLanding/utils/productPricingHelpers';
import { payNowCheckoutOnPayLink } from '../../PayNow/checkout';
import { SIGNUP_BASE_PATH } from '../../Signup/routes';
import { buildQueryString } from '../../utils/queryParams';
import OrderLandingPage from '../OrderLandingPage/OrderLandingPage';

export const OrderCheckoutPage = () => {
  const skipLandingPage = useSkipEcomLanding();
  const [showIntroduction, setShowIntroduction] = useState(false);
  useEffect(() => setShowIntroduction(!skipLandingPage), [skipLandingPage]);

  const { orderId } = useParams();

  const { ecommerceOrder, loadingEcommerceOrder, ecommerceOrderError } =
    useGetEcommerceOrder({
      orderId,
    });

  const { payLink, loadingPayLink } = useGetPayLinkByPayLinkId({
    payLinkId: ecommerceOrder?.pay_link_id,
  });

  const { formalOffers, loadingFormalOffers } =
    useGetValidIwocapayFormalOffers();

  const { seller, loadingSeller } = useGetSellerByHandle(
    payLink?.seller_handle,
  );

  const { enabledOptions, loadingOptions } = useEnabledOptions({
    pricing: payLink?.product_pricing,
    allowedOptions: ecommerceOrder?.allowed_payment_terms,
  });

  const [loginPanelOpen, setLoginPanelOpen] = useState(false);

  useTrackLandingPageView({
    payLinkType: 'custom',
    visibleOptions: enabledOptions,
    payLinkId: payLink?.id,
    productPricing: payLink?.product_pricing!,
    sellerId: seller?.seller_id,
    loadingApis: loadingSeller || loadingPayLink,
  });

  useThreatMetrix();

  const isOverlay = useIsOverlay();
  const cancelUrl = payLink?.cancel_url || ecommerceOrder?.redirect_url;

  if (ecommerceOrderError) {
    return <OrderNotFound />;
  }

  if (
    (orderId && loadingEcommerceOrder) ||
    loadingPayLink ||
    loadingOptions ||
    loadingFormalOffers ||
    loadingSeller
  ) {
    return <LoadingScreen />;
  }

  if (!ecommerceOrder) {
    return <OrderNotFound />;
  }

  if (!payLink) {
    // This shouldn't happen, but helps typescript to infer the following types correctly
    return <PayLinkNotFound />;
  }

  if (ecommerceOrder.status !== 'CREATED') {
    return <OrderLinkIsNotValid />;
  }

  if (showIntroduction) {
    return <OrderLandingPage showCheckout={() => setShowIntroduction(false)} />;
  }

  const pricingPromotions = getPricingPromotions({
    productPricing: payLink.product_pricing,
  });

  const buyerInterest = getBuyerInterest({
    productPricing: payLink.product_pricing,
    formalOffers: formalOffers,
  });

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.paymentDetailsColumn}>
            <CancelButton
              className={styles.cancelButton}
              href={cancelUrl}
              orderId={orderId}
            />
            <LoginBanner onClick={() => setLoginPanelOpen(true)} />
            <PaymentDetails
              isPreview={false}
              sellerName={payLink.seller_trading_name}
              pricingPromotions={pricingPromotions}
              buyerInterest={buyerInterest}
              onSubmit={onSubmit(
                payLink,
                { data: ecommerceOrder },
                enabledOptions,
                isOverlay,
                formalOffers,
              )}
              initialValues={{
                amount: payLink.amount.toString(),
                reference: payLink.reference,
              }}
              lockAmount={true}
              lockReference={true}
              enabledOptions={enabledOptions}
              payLinkType="order"
            />
            <div className="pt-xl text-center text-m font-reg text-black">
              Find out how we use your data in our&nbsp;
              <a
                href="/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
          <div className={styles.helpAccordionColumn}>
            <HelpTextAccordion
              className={styles.helpAccordion}
              enabledOptions={enabledOptions}
            />
          </div>
        </div>
      </div>
      <LoginPanel
        open={loginPanelOpen}
        close={() => setLoginPanelOpen(false)}
      />
    </div>
  );
};

const onSubmit =
  (
    payLink: PayLink,
    order: Order,
    enabledOptions: TEnabledOptions,
    isOverlay: boolean,
    formalOffers?: FormalOffer[],
  ) =>
  ({ values, stateKey }: { values: FormValues; stateKey?: string }) => {
    const {
      paymentOption,
      payNowFullName,
      payNowEmailAddress,
      payNowMarketingConsent: marketingConsent,
    } = values;

    if (paymentOption === 'payNow')
      return payNowCheckoutOnPayLink({
        payLinkId: payLink.id,
        customerDetails: {
          fullName: payNowFullName,
          emailAddress: payNowEmailAddress,
        },
        marketingConsent,
        isOverlay,
      });

    return onSubmitPayLater({
      values,
      payLink,
      order,
      stateKey,
      formalOffers,
      enabledOptions,
    });
  };

const onSubmitPayLater = async ({
  values,
  payLink,
  order,
  stateKey,
  formalOffers,
  enabledOptions,
}: {
  values: FormValues;
  payLink: PayLink;
  order: Order;
  stateKey?: string;
  formalOffers?: FormalOffer[];
  enabledOptions: TEnabledOptions;
}) => {
  const queryParams = {
    orderId: order.data.id,
    payLinkId: order.data.pay_link_id,
  };
  const queryString = buildQueryString(queryParams);

  const selectedOption = (
    values.paymentOption === 'payLater3' ? 'THREE_MONTHS' : 'TWELVE_MONTHS'
  ) as RequestedDuration;

  trackSelectedCheckoutOption({
    payLinkId: payLink.id,
    visibleOptions: enabledOptions,
    selectedOption: selectedOption,
  });

  if (stateKey) {
    await submitBuyerStepZeroReturningCustomer(
      stateKey,
      payLink,
      values.paymentOption!,
    );

    await fetchPostSharedUserAction({
      stateKey,
      body: { user_action: 'CHECKOUT_PAY_LINK' },
    });

    if (
      shouldRedirectToCheckout({
        paymentOption: values.paymentOption,
        payLinkAmount: parseFloat(values.amount),
        formalOffers,
      })
    ) {
      const offerId = getRelevantFormalOfferId({
        paymentOption: values.paymentOption,
        formalOffers,
      });

      if (offerId) {
        trackSelectedOffer({
          payLinkId: payLink.id,
          offerIds: formalOffers!.map((offer) => offer.offer_id!),
          offerId: offerId,
        });

        window.location.href = `/pay/checkout/offer/${buildQueryString({
          ...queryParams,
          offerId: offerId,
        })}`;

        return;
      }
    }

    window.location.href = `/account/iwocapay/${queryString}`;
  } else {
    const customerState = await submitBuyerStepZeroNewCustomer(
      values.emailAddress,
      values.marketingOptIn,
      payLink,
      values.paymentOption!,
    );

    await fetchPostSharedUserAction({
      stateKey: customerState.data!.state_key,
      body: { user_action: 'CHECKOUT_PAY_LINK' },
    });

    window.location.href = `${SIGNUP_BASE_PATH}${queryString}`;
  }
};
