import { useContext, useEffect, useRef, useState } from 'react';

import { fetchPostSellerPayLink } from '@iwoca/lapi-client/iwocapay';
import { Button } from '@iwoca/orion';
import * as Yup from 'yup';

import { ActivationContext } from '../../../activation/Activation';
import { useGetSharedFunctionality } from '../../../api/lending/lapiHooks';
import { TPricingType } from '../../../Buyer/PayLinkLanding/utils/PayLinkLanding.types';
import { HideFor } from '../../../components/ConditionalDisplay/ConditionalDisplay';
import { LockedButton } from '../../../components/LockedButton/LockedButton';
import { PayMeLink } from '../../../components/PayMeLink/PayMeLink';
import { useSellerHandle } from '../../../hooks/useSellerHandle.hook';
import { useStateKey } from '../../../hooks/useStateKey.hook';
import { createToast } from '../../../store/IwToast';
import { getPayLinkUrl } from '../../../utils/getPayLinkUrl';
import {
  PayLinkInputs,
  PricingTermOption,
} from '../../ManageAccount/components/PayLaterPricingSettings/PayLaterPricingSettings';
import { CheckoutPreview } from '../components/CheckoutPreview/CheckoutPreview';
import { PricingOptionDescriptions } from '../components/PricingOptionDescriptions/PricingOptionDescriptions';
import {
  Column,
  VerticalSplitLayout,
} from '../components/VerticalSplitLayout/VerticalSplitLayout';
import { useProduct } from '../hooks/useProduct';
import {
  DEFAULT_12M_PRICING_OPTION,
  DEFAULT_30D_PRICING_OPTION,
} from '../PayLinksNavigation';
import { usePreview } from '../usePreview';
import { validateForm } from '../utils/validateForm';

export const OneTimeLinksPage = () => {
  const { sellerHandle } = useSellerHandle();
  const { previewValues, setPreviewValues } = usePreview();

  if (!sellerHandle) return null;

  return (
    <VerticalSplitLayout>
      <Column>
        <LeftSide
          previewValues={previewValues}
          setPreviewValues={setPreviewValues}
        />
      </Column>
      <Column>
        {previewValues.pricing_3m ? (
          <CheckoutPreview
            sellerName={previewValues.sellerName}
            amount={previewValues.amount}
            reference={previewValues.reference}
            pricingPromotions={{
              THREE_MONTHS: previewValues.pricing_3m.promotions,
              TWELVE_MONTHS: previewValues.pricing_12m?.promotions,
              THIRTY_DAYS: previewValues.pricing_30d?.promotions,
            }}
            representativeInterest={{
              THREE_MONTHS: previewValues.pricing_3m.representativeInterest,
              TWELVE_MONTHS: previewValues.pricing_12m?.representativeInterest,
              THIRTY_DAYS: previewValues.pricing_30d?.representativeInterest,
            }}
          />
        ) : (
          <div>Loading preview...</div>
        )}
      </Column>
    </VerticalSplitLayout>
  );
};

const LeftSide = ({
  previewValues,
  setPreviewValues,
}: {
  previewValues: ReturnType<typeof usePreview>['previewValues'];
  setPreviewValues: (name: string, value: unknown) => void;
}) => {
  const { functionality } = useGetSharedFunctionality();
  const formRef = useRef<HTMLFormElement>(null);
  const [payLink, setPayLink] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [touched, setTouched] = useState<{ [key: string]: string }>({});

  const [amount, setAmount] = useState('');
  const [reference, setReference] = useState('');
  const [threeMonthPricing, setThreeMonthPricing] = useState<TPricingType>();
  const [twelveMonthPricing, setTwelveMonthPricing] = useState<TPricingType>();
  const [thirtyDayPricing, setThirtyDayPricing] = useState<TPricingType>();

  const { productEnabled: is12MonthsEnabled } = useProduct('TWELVE_MONTHS');
  const { productEnabled: is30DaysEnabled } = useProduct('THIRTY_DAYS');

  useEffect(() => {
    setThreeMonthPricing(previewValues.pricing_3m?.type as TPricingType);
    setTwelveMonthPricing(
      (previewValues.pricing_12m?.type as TPricingType) ||
        DEFAULT_12M_PRICING_OPTION,
    );
    setThirtyDayPricing(
      (previewValues.pricing_30d?.type as TPricingType) ||
        DEFAULT_30D_PRICING_OPTION,
    );
  }, [
    previewValues.pricing_3m,
    previewValues.pricing_12m,
    previewValues.pricing_30d,
  ]);

  const { stateKey } = useStateKey();
  const { isOnboarded } = useContext(ActivationContext);

  const formSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError('Please provide a pay link amount')
      .min(0, 'Please provide a pay link amount')
      .required(),
    reference: Yup.string()
      .max(50, 'Limit 50 characters reached')
      .required('Please provide a pay link reference'),
  });

  const submit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const data = await validateForm(ev.currentTarget, formSchema, setErrors);

    if (data && previewValues.pricing_3m) {
      const body = {
        reference: data.reference,
        amount: data.amount,
        product_pricing: {
          THREE_MONTHS: previewValues.pricing_3m.type as TPricingType,
          ...(previewValues.pricing_12m && {
            TWELVE_MONTHS: previewValues.pricing_12m.type as TPricingType,
          }),
          ...(previewValues.pricing_30d && {
            THIRTY_DAYS: previewValues.pricing_30d.type as TPricingType,
          }),
        },
      };

      try {
        const { seller_name, id } = await fetchPostSellerPayLink({
          stateKey: stateKey!,
          body: body,
        });

        const payLinkUrl = getPayLinkUrl(seller_name!, id!);
        setPayLink(payLinkUrl);
      } catch (e) {
        createToast(ERROR_MESSAGE);
      }
    }
  };

  return (
    <div className="mt-2xl grid grid-cols-1 gap-xl">
      <div>
        <h1 className="mb-0 mt-0 text-2xl font-bold text-primary-10">
          A one-time custom pay link
        </h1>
        <p className="mb-0 mt-s text-m font-reg text-primary-10">
          To be used once for a specific payment.
        </p>
      </div>

      <div>
        <h2 className="mb-0 mt-0 text-l font-bold text-primary-10">
          Choose who pays for this Pay Later via this pay link
        </h2>
        <p className="mb-0 mt-s text-m font-reg text-primary-10">
          These settings will apply only to this pay link.
        </p>
      </div>

      <form
        ref={formRef}
        aria-label="customPayLinkForm"
        className="grid grid-cols-1 gap-xl"
        onSubmit={submit}
      >
        <PayLinkInputs
          amount={amount}
          setAmount={setAmount}
          reference={reference}
          setReference={setReference}
          setPreviewValues={setPreviewValues}
          validateForm={() =>
            validateForm(formRef.current!, formSchema, setErrors)
          }
          setTouched={setTouched}
          errors={errors}
          touched={touched}
        />

        <HideFor contributor fallback={<PricingOptionDescriptions />}>
          <PricingTermOption
            productKey={'THREE_MONTHS'}
            pricingType={threeMonthPricing}
            onChange={(pricingType) => {
              setThreeMonthPricing(pricingType);
              setPreviewValues('pricing_3m', pricingType);
            }}
            enabled={true}
          />

          <PricingTermOption
            productKey={'TWELVE_MONTHS'}
            pricingType={twelveMonthPricing}
            onChange={(pricingType) => {
              setTwelveMonthPricing(pricingType);
              setPreviewValues('pricing_12m', pricingType);
            }}
            onToggle={(enabled) => {
              const terms = enabled ? 'SELLER_FREE_BUYER_PAYS' : null;
              setPreviewValues('pricing_12m', terms);
            }}
            enabled={is12MonthsEnabled}
          />
          {functionality?.is_30_days_enabled && (
            <PricingTermOption
              productKey={'THIRTY_DAYS'}
              pricingType={thirtyDayPricing}
              onChange={(pricingType) => {
                setThirtyDayPricing(pricingType);
                setPreviewValues('pricing_30d', pricingType);
              }}
              onToggle={(enabled) => {
                const terms = enabled ? 'SELLER_FREE_BUYER_PAYS' : null;
                setPreviewValues('pricing_30d', terms);
              }}
              enabled={is30DaysEnabled}
            />
          )}
        </HideFor>

        <div className="flex flex-col gap-m">
          {payLink && <PayMeLink payMeUrl={payLink} />}
          {isOnboarded ? (
            <Button
              disabled={
                Object.keys(errors).length > 0 ||
                Object.keys(touched).length !== 2
              }
              className="w-fit"
            >
              Generate one-time custom link
            </Button>
          ) : (
            <LockedButton />
          )}
        </div>
      </form>
    </div>
  );
};

const ERROR_MESSAGE =
  'Error creating pay link. Please refresh the page and try again.';
