import { useState } from 'react';

import { fetchPostXeroLogin } from '@iwoca/lapi-client/edge';
import { Button, Icon } from '@iwoca/orion';

import { Accordion } from '../../../Buyer/components/Accordion/Accordion';
import { buildQueryString } from '../../../Buyer/utils/queryParams';
import { ProgressIndicator } from '../../../components/ProgressIndicator/ProgressIndicator';
import { useStateKey } from '../../../hooks/useStateKey.hook';

export function Connect() {
  const { stateKey } = useStateKey();
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const redirectUserToXero = async () => {
    setIsRedirecting(true);
    setError(null);

    if (!stateKey) {
      return setError('Unexpected error.');
    }

    const queryString = buildQueryString();

    try {
      const response = await fetchPostXeroLogin({
        reason: 'iwocapay_seller',
        stateKey: stateKey,
        body: {
          data: {
            redirect_to: `/pay/xero/authorisation/${queryString}`,
          },
        },
      });
      window.location.href = response.data.authorization_url;
    } catch (error) {
      setError('We weren’t able to redirect you, please try again');
    } finally {
      setIsRedirecting(false);
    }
  };

  return (
    <div className="mx-auto my-s max-w-[497px] p-l text-primary-10">
      <ProgressIndicator
        progress={[
          { title: 'Connect', isActive: true, percentage: 100 },
          { title: 'Authorisation', isActive: false, percentage: 0 },
          { title: 'Configuration', isActive: false, percentage: 0 },
        ]}
        className="!mt-xl !p-0"
      />
      <div className="mb-2xl">
        <div>
          <h1 className="m-0 mb-xl mt-xl text-4xl">
            How we use your information
          </h1>
          <div className="mb-l text-l font-reg">
            We will access the following information stored on your Xero account
            for the purpose of your iwocaPay account:
          </div>
          <div className="my-2xl flex items-center font-med">
            <Icon icon="building" className="mr-l h-auto w-3xl" />
            <p className="m-0">
              <b>Business details</b> (inc.your business name, business address
              and contact details)
            </p>
          </div>
          <div className="my-2xl flex items-center font-med">
            <Icon icon="fileEmptyOutline" className="mr-l h-auto w-3xl" />
            <p className="m-0">
              <b>Invoice details</b> (inc. your branding themes, invoice amounts
              and customer contact details)
            </p>
          </div>
          <Accordion title="Will I be charged for this connection?">
            <p>
              <strong>You will not be charged for this connection,</strong> but
              Xero may receive a fee from iwocaPay for facilitating it. By
              continuing with this process, you are consenting to the payment by
              iwocaPay of such fee.
            </p>
          </Accordion>
          <Accordion title="How is my data stored?">
            <p>
              If you successfully connect iwocaPay with your Xero account, we
              will have access to the above data until you revoke our access
              through your Xero account.{' '}
              <strong>
                Your credentials are encrypted and we will never store your
                password.
              </strong>
            </p>
          </Accordion>
          <Accordion title="What data is shared?">
            <p>
              If you agree, we will share records of your iwocaPay account with
              Xero, including details of payments we process for you.{' '}
              <strong>
                Any information shared with Xero will be retained in your Xero
                account and treated in accordance with Xero’s standard terms of
                use.
              </strong>
            </p>
          </Accordion>
          <Accordion title="Your agreement">
            <>
              <p>
                <strong>
                  By continuing, you acknowledge that Xero’s role in iwoca’s
                  services is limited to providing a direct data feed to
                  iwoca{' '}
                </strong>
                from your subscription to Xero to enable you to conveniently use
                iwocaPay without manually retyping data, and to update your Xero
                profile without manually retyping data.
              </p>
              <p>
                <strong>
                  Xero is not responsible for, and has no liability to you or
                  iwoca in respect of, the accuracy of the data or the
                  suitability of any financial product{' '}
                </strong>
                or service provided by iwoca or a connected party for your needs
                and has no other involvement in iwoca’s services other than the
                provision of the data.
              </p>
            </>
          </Accordion>
        </div>
      </div>
      <Button
        className="w-full"
        loading={isRedirecting}
        onClick={redirectUserToXero}
      >
        Connect your Xero account
      </Button>
      {error && <p className="text-error">{error}</p>}
    </div>
  );
}
