import { useState } from 'react';

import { fetchPostSharedUserAction } from '@iwoca/lapi-client/iwocapay';

import styles from './GenericPayLinkLanding/GenericPayLinkLanding.module.css';
import { HelpTextAccordion } from './HelpTextAccordion/HelpTextAccordion';
import { useGetValidIwocapayFormalOffers } from './hooks/useGetValidIwocapayFormalOffers';
import {
  submitBuyerStepZeroNewCustomer,
  submitBuyerStepZeroReturningCustomer,
} from './PayLater/CreateUserForm/submitBuyerStepZero';
import { PaymentDetails } from './PaymentDetails/PaymentDetails';
import { FormValues, PayLink } from './utils/PayLinkLanding.types';
import {
  getBuyerInterest,
  getPricingPromotions,
  getRelevantFormalOfferId,
  shouldRedirectToCheckout,
} from './utils/productPricingHelpers';
import { RequestedDuration } from '../../api/lending/edge';
import {
  SELLER_GET_ERROR,
  useGetPayLinkByPayLinkId,
  useGetSellerByHandle,
} from '../../api/lending/lapiHooks';
import { LoadingScreen } from '../../components/LoadingScreen/LoadingScreen';
import { GenericError } from '../../Pages/FailurePages/GenericError';
import { PayLinkNotFound } from '../../Pages/FailurePages/PayLinkNotFound';
import { PayLinkTerminalState } from '../../Pages/FailurePages/PayLinkTerminalState';
import { FormalOffer } from '../../Seller/PayLinks/utils/Paylinks.types';
import { useThreatMetrix } from '../../utils/ThreatMetrix';
import {
  trackSelectedCheckoutOption,
  trackSelectedOffer,
} from '../../utils/tracking';
import { useCurrentEcommerceOrder } from '../Checkout/hooks/useCurrentEcommerceOrder';
import { CancelButton } from '../components/CancelButton/CancelButton';
import { LoginBanner } from '../components/LoginBanner';
import { TEnabledOptions, useEnabledOptions } from '../hooks/useEnabledTerms';
import { useIsOverlay } from '../hooks/useIsOverlay';
import { useTrackLandingPageView } from '../hooks/useTrackLandingPageView';
import { LoginPanel } from '../LoginPanel/LoginPanel';
import { payNowCheckoutOnPayLink } from '../PayNow/checkout';
import { SIGNUP_BASE_PATH } from '../Signup/routes';
import { buildQueryString } from '../utils/queryParams';

export const PayLinkLanding = ({ payLinkId }: { payLinkId: string }) => {
  const cleanedPayLinkId = cleanPayLinkId(payLinkId);
  const { payLink, loadingPayLink, payLinkError } = useGetPayLinkByPayLinkId({
    payLinkId: cleanedPayLinkId,
  });
  const { ecommerceOrder } = useCurrentEcommerceOrder();

  const { enabledOptions, loadingOptions } = useEnabledOptions({
    pricing: payLink?.product_pricing,
    allowedOptions: payLink?.allowed_payment_terms,
  });
  const { seller, sellerError, loadingSeller } = useGetSellerByHandle(
    payLink?.seller_handle,
  );
  const { formalOffers, loadingFormalOffers } =
    useGetValidIwocapayFormalOffers();

  const [loginPanelOpen, setLoginPanelOpen] = useState(false);

  useTrackLandingPageView({
    payLinkType: 'custom',
    visibleOptions: enabledOptions,
    payLinkId: payLink?.id,
    productPricing: payLink?.product_pricing,
    sellerId: seller?.seller_id,
    loadingApis: loadingPayLink || loadingSeller,
  });

  useThreatMetrix();

  const isOverlay = useIsOverlay();

  if (sellerError) {
    return <GenericError errorText={"We can't find the seller"} />;
  }

  if (payLinkError) {
    return <PayLinkNotFound />;
  }

  if (
    loadingPayLink ||
    !payLink ||
    loadingSeller ||
    loadingOptions ||
    loadingFormalOffers
  ) {
    return <LoadingScreen />;
  }

  if (payLink.deleted_at) {
    return <GenericError errorText={SELLER_GET_ERROR} />;
  }

  if (payLink.in_terminal_state) {
    return <PayLinkTerminalState />;
  }

  const pricingPromotions = getPricingPromotions({
    productPricing: payLink.product_pricing,
  });

  const buyerInterest = getBuyerInterest({
    productPricing: payLink.product_pricing,
    formalOffers: formalOffers,
  });

  const cancelUrl = payLink?.cancel_url || ecommerceOrder?.redirect_url;

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.paymentDetailsColumn}>
            <CancelButton
              className={styles.cancelButton}
              href={cancelUrl}
              orderId={ecommerceOrder?.id}
            />
            <LoginBanner onClick={() => setLoginPanelOpen(true)} />
            <PaymentDetails
              isPreview={false}
              sellerName={payLink.seller_trading_name}
              pricingPromotions={pricingPromotions}
              buyerInterest={buyerInterest}
              onSubmit={onSubmit({
                payLink,
                formalOffers,
                enabledOptions,
                isOverlay,
              })}
              initialValues={{
                amount: payLink.amount.toString(),
                reference: payLink.reference,
              }}
              lockAmount={true}
              lockReference={true}
              enabledOptions={enabledOptions}
              payLinkType="custom"
            />
            <div className="pt-xl text-center text-m font-reg text-black">
              Find out how we use your data in our&nbsp;
              <a
                href="/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
          <div className={styles.helpAccordionColumn}>
            <HelpTextAccordion
              className={styles.helpAccordion}
              enabledOptions={enabledOptions}
            />
          </div>
        </div>
      </div>
      <LoginPanel
        open={loginPanelOpen}
        close={() => setLoginPanelOpen(false)}
      />
    </div>
  );
};

const onSubmit = ({
  payLink,
  formalOffers,
  enabledOptions,
  isOverlay,
}: {
  payLink: PayLink;
  formalOffers?: FormalOffer[];
  enabledOptions: TEnabledOptions;
  isOverlay: boolean;
}) => {
  return ({ values, stateKey }: { values: FormValues; stateKey?: string }) => {
    const {
      paymentOption,
      payNowFullName,
      payNowEmailAddress,
      payNowMarketingConsent: marketingConsent,
    } = values;

    if (paymentOption === 'payNow')
      return payNowCheckoutOnPayLink({
        payLinkId: payLink.id,
        customerDetails: {
          fullName: payNowFullName,
          emailAddress: payNowEmailAddress,
        },
        marketingConsent,
        isOverlay,
      });

    return onSubmitPayLater({
      values,
      payLink,
      stateKey,
      formalOffers,
      enabledOptions,
    });
  };
};

const onSubmitPayLater = async ({
  values,
  payLink,
  stateKey,
  formalOffers,
  enabledOptions,
}: {
  values: FormValues;
  payLink: PayLink;
  stateKey?: string;
  formalOffers?: FormalOffer[];
  enabledOptions: TEnabledOptions;
}) => {
  const queryParams = { payLinkId: payLink.id };
  const queryString = buildQueryString(queryParams);

  const selectedOption = (
    values.paymentOption === 'payLater3' ? 'THREE_MONTHS' : 'TWELVE_MONTHS'
  ) as RequestedDuration;

  trackSelectedCheckoutOption({
    payLinkId: payLink.id,
    visibleOptions: enabledOptions,
    selectedOption: selectedOption,
  });

  if (stateKey) {
    await submitBuyerStepZeroReturningCustomer(
      stateKey,
      payLink,
      values.paymentOption!,
    );

    await fetchPostSharedUserAction({
      stateKey,
      body: { user_action: 'CHECKOUT_PAY_LINK' },
    });

    if (
      shouldRedirectToCheckout({
        paymentOption: values.paymentOption,
        payLinkAmount: parseFloat(values.amount),
        formalOffers,
      })
    ) {
      const offerId = getRelevantFormalOfferId({
        paymentOption: values.paymentOption,
        formalOffers,
      });
      if (offerId) {
        trackSelectedOffer({
          payLinkId: payLink.id,
          offerIds: formalOffers!.map((offer) => offer.offer_id!),
          offerId: offerId,
        });

        window.location.href = `/pay/checkout/offer/${buildQueryString({
          ...queryParams,
          ...(offerId && { offerId: offerId }),
        })}`;
        return;
      }
    }

    window.location.href = `/account/iwocapay/${queryString}`;
    return;
  }

  const customerState = await submitBuyerStepZeroNewCustomer(
    values.emailAddress,
    values.marketingOptIn,
    payLink,
    values.paymentOption!,
  );

  await fetchPostSharedUserAction({
    stateKey: customerState.data!.state_key,
    body: { user_action: 'CHECKOUT_PAY_LINK' },
  });

  window.location.href = `${SIGNUP_BASE_PATH}${queryString}`;
};

const cleanPayLinkId = (payLinkId: string) => {
  return payLinkId.replace(' ', '');
};
