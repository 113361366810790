import { fetchPostXeroLogin } from '@iwoca/lapi-client/edge';
import { AlertBox, Icon } from '@iwoca/orion';
import cn from 'classnames';
import { useParams } from 'react-router';

import externalLinkSVG from '../../../../assets/externalLink.svg';
import { buildQueryString } from '../../../../Buyer/utils/queryParams';
import { useStateKey } from '../../../../hooks/useStateKey.hook';
import { createToast } from '../../../../store/IwToast';
import { useIwocapaySellerXeroAccount } from '../../../Xero/Xero.helpers';
import tickSVG from '../../assets/tick.svg';
import { useGetIntegrationStatus } from '../../hooks/useGetIntegrationStatus.hook';
import { Xero } from '../../Integrations';
import { ConnectButton } from '../ConnectButton/ConnectButton';
import bodyStyles from '../IntegrationBody.module.css';
import { IntegrationsTitle } from '../IntegrationsTitle/IntegrationsTitle';

export function XeroBody() {
  const { stateKey } = useStateKey();
  const { integrationParam } = useParams();
  const { integrationStatus, loadingIwocapaySellerConnection } =
    useGetIntegrationStatus({
      integrationId: Xero.id,
    });
  const isConnected = integrationStatus === 'CONNECTED';
  const { data: iwocapayXeroAccount } = useIwocapaySellerXeroAccount({
    enabled: isConnected,
  });

  const buttonText = isConnected ? 'Configure plugin' : 'Connect';
  const buttonLink = isConnected ? '/pay/xero/account' : '/pay/xero/connect';

  const redirectUserToXero = async () => {
    if (!stateKey) {
      createToast('Unexpected error.', {
        variant: 'failure',
      });
      return;
    }

    const queryString = buildQueryString();
    try {
      const response = await fetchPostXeroLogin({
        reason: 'iwocapay_seller',
        stateKey: stateKey!,
        body: {
          data: {
            redirect_to: `/pay/xero/authorisation/${queryString}`,
          },
        },
      });
      window.location.href = response.data.authorization_url;
    } catch (error) {
      createToast('We weren’t able to redirect you, please try again.', {
        variant: 'failure',
      });
    }
  };

  const redirectToRelevantPath = () => {
    if (isConnected) {
      // For Seller fee reporting we needed to extend the oAuth scopes for Xero
      // so we assume if they haven't linked a Fee account they likely haven't re-authed us
      const initial_feeAccount_id =
        // @ts-expect-error fee_account needs to be added to endpoint response
        iwocapayXeroAccount?.data?.seller_account?.fee_account?.fee_account_id;

      if (!initial_feeAccount_id) {
        void redirectUserToXero();
        return;
      }
    }
    const queryString = buildQueryString();
    window.location.href = `${buttonLink}${queryString}`;
  };

  if (integrationParam !== Xero.id) {
    return null;
  }
  return (
    <div className={bodyStyles.container}>
      <div className={bodyStyles.header}>
        <IntegrationsTitle integration={Xero} />
        <div
          className={cn(bodyStyles.connectButtonWrapper, {
            [bodyStyles.connectButtonWrapperConnected]: isConnected,
          })}
        >
          {isConnected && !loadingIwocapaySellerConnection && (
            <div className={bodyStyles.isConnected}>
              <img
                src={tickSVG}
                alt="Tick Icon"
                className={bodyStyles.tickSVG}
              />
              Connected
            </div>
          )}
          {!loadingIwocapaySellerConnection && (
            <ConnectButton
              isConnected={isConnected}
              onClick={redirectToRelevantPath}
            >
              {buttonText}
            </ConnectButton>
          )}
        </div>
      </div>
      <div className={bodyStyles.body}>
        <div>
          <p>
            Online accounting software for your business connects you to your
            bank, accountant, bookkeeper, and other business apps.
          </p>
          <h3 className={bodyStyles.subHeading}>
            When you connect iwocaPay to Xero you can:
          </h3>
          <ul className={bodyStyles.bulletList}>
            <li>
              include a ‘Pay with iwocaPay’ button on Xero invoices, without
              creating pay links
            </li>
            <li>
              automatically record invoice payments in Xero when your customers
              use iwocaPay.
            </li>
          </ul>
          <h3 className={bodyStyles.subHeading}>What you'll need in Xero:</h3>
          <ul className={bodyStyles.bulletList}>
            <li>an account for your iwocaPay payments to be logged in</li>
            <li>
              an invoice branding theme you’d like to add an iwocaPay button to.
            </li>
          </ul>
          <div>
            <AlertBox variant="connect" className="mt-2xl flex gap-s">
              <Icon icon="alertCircle" className="mt-xs min-w-[19px]" />
              <div>
                <strong>
                  You’ll need to set up Two-Factor Authentication to keep your
                  account secure -
                </strong>{' '}
                You’ll need another device (like your phone) to set it up. We’ll
                send a security code to that device you’ll need to enter every
                time you access your iwocaPay account, so make sure it’s one you
                have access to.
              </div>
            </AlertBox>
            <a
              href={
                'https://ecommerce-api.iwoca.com/guides/plugin-solution/xero/'
              }
              target="_blank"
              rel="noreferrer"
              className={bodyStyles.faqLink}
            >
              Xero FAQs
              <img
                src={externalLinkSVG}
                alt="External Link Icon"
                className={bodyStyles.externalLinkIcon}
              />
            </a>
          </div>
        </div>
      </div>
      <div className={bodyStyles.footer}>
        {!loadingIwocapaySellerConnection && (
          <ConnectButton isConnected={isConnected} link={buttonLink}>
            {buttonText}
          </ConnectButton>
        )}
      </div>
    </div>
  );
}
