import classnames from 'classnames';
import { useMatch } from 'react-router';

import styles from './PayLinksNavigation.module.css';
import { LinkWithQuery } from '../../components/LinkWithQuery/LinkWithQuery';

export const DEFAULT_12M_PRICING_OPTION = 'SELLER_FREE_BUYER_PAYS';
export const DEFAULT_30D_PRICING_OPTION = 'SELLER_FREE_BUYER_PAYS';

export const PayLinksNavigation = () => {
  return (
    <nav className={styles.secondaryNavbar}>
      <div className={styles.secondaryNavbarInner}>
        <div className={styles.navigationLinks}>
          <PayLinkNavBarLink to="/pay/pay-links/universal/">
            Universal link
          </PayLinkNavBarLink>
          <PayLinkNavBarLink to="/pay/pay-links/package/">
            Package links
          </PayLinkNavBarLink>
          <PayLinkNavBarLink to="/pay/pay-links/custom/">
            Custom links
          </PayLinkNavBarLink>

          <a
            href="https://support.iwoca.co.uk/en/collections/2145896-iwocapay-the-simple-checkout-for-your-invoices"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.navigationLink}
          >
            Pay links FAQ
          </a>
        </div>
      </div>
    </nav>
  );
};

const PayLinkNavBarLink = ({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) => {
  return (
    <NavBarLink
      to={to}
      className={styles.navigationLink}
      activeClass={styles.navigationLinkActive}
    >
      {children}
    </NavBarLink>
  );
};

const NavBarLink = ({
  to,
  children,
  className,
  activeClass,
  isExternalLink,
  ...props
}: {
  to: string;
  children: React.ReactNode;
  className?: string;
  activeClass?: string;
  isExternalLink?: boolean;
}) => {
  const match = useMatch(to + '*');
  const getLinkClassName = (): string | undefined => {
    if (!activeClass) return className;

    return classnames(className, {
      [activeClass]: match,
    });
  };

  return isExternalLink ? (
    <a {...props} href={to} className={getLinkClassName()}>
      {children}
    </a>
  ) : (
    <LinkWithQuery {...props} to={to} className={getLinkClassName()}>
      {children}
    </LinkWithQuery>
  );
};
