import { useCallback, useState, useEffect } from 'react';

import { useGetSellerBranding } from '../../api/lending/lapiHooks';
import { useSellerPricing } from '../../hooks/useSellerPricing';

export function usePreview() {
  const { sellerPricing } = useSellerPricing();
  const { sellerBranding } = useGetSellerBranding();

  const [previewValuesState, setPreviewValuesState] = useState({
    amount: '',
    reference: '',
    pricing_3m: '',
    pricing_12m: '',
    pricing_30d: '',
    sellerName: '',
  });

  const setPreviewValues = useCallback(
    (name: string, value: unknown) => {
      setPreviewValuesState((prevValues) => {
        if (!Object.keys(prevValues).includes(name)) return prevValues;

        return {
          ...prevValues,
          [name]: value,
        };
      });
    },
    [setPreviewValuesState],
  );

  const setPreviewValuesFromEvent = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPreviewValues(e.target.name, e.target.value);
  };

  useEffect(() => {
    if (sellerPricing?.product_pricing_options !== undefined) {
      setPreviewValues(
        'pricing_3m',
        sellerPricing?.product_pricing_options.THREE_MONTHS.find(
          ({ is_enabled }) => is_enabled,
        )?.pricing_type,
      );
      setPreviewValues(
        'pricing_12m',
        sellerPricing?.product_pricing_options.TWELVE_MONTHS?.find(
          ({ is_enabled }) => is_enabled,
        )?.pricing_type,
      );
      setPreviewValues(
        'pricing_30d',
        sellerPricing?.product_pricing_options.THIRTY_DAYS?.find(
          ({ is_enabled }) => is_enabled,
        )?.pricing_type,
      );
    }
  }, [sellerPricing, setPreviewValues]);

  const products = [
    { name: 'THREE_MONTHS', shortName: '3m' },
    { name: 'TWELVE_MONTHS', shortName: '12m' },
    { name: 'THIRTY_DAYS', shortName: '30d' },
  ];

  type TPricing = {
    type: string;
    promotions: string[];
    representativeInterest: number;
  } | null;
  type TProductPricing = {
    pricing_12m: TPricing;
    pricing_3m: TPricing;
    pricing_30d: TPricing;
  };

  const productPricing: TProductPricing = {
    pricing_12m: null,
    pricing_3m: null,
    pricing_30d: null,
  };
  for (const product of products) {
    const product_pricing_options = sellerPricing?.product_pricing_options;
    const productName = product.name as keyof NonNullable<
      typeof product_pricing_options
    >;
    const productShortName = product.shortName;
    const pricingName = `pricing_${productShortName}` as keyof TProductPricing;

    const pricingDetails = sellerPricing?.product_pricing_options[
      productName
    ]?.find(
      ({ pricing_type }) => pricing_type === previewValuesState[pricingName],
    )!;

    productPricing[pricingName] =
      previewValuesState[pricingName] && pricingDetails
        ? {
            type: previewValuesState[pricingName],
            promotions: pricingDetails.buyer.promotions,
            representativeInterest:
              pricingDetails.buyer.representative_interest,
          }
        : null;
  }

  return {
    previewValues: {
      amount: previewValuesState.amount,
      reference: previewValuesState.reference,
      sellerName: sellerBranding?.trading_name || '',
      ...productPricing,
    },
    setPreviewValuesFromEvent,
    setPreviewValues,
  };
}
