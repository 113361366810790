import { useContext, useEffect, useRef, useState } from 'react';

import { fetchPostSellerPayLinkPackage } from '@iwoca/lapi-client/iwocapay';
import { Button, Input } from '@iwoca/orion';
import * as Yup from 'yup';

import { ExistingPackageLinksTable } from './ExistingPackageLinksTable/ExistingPackageLinksTable';
import styles from './PackageLinkPage.module.css';
import { PackageLink } from './PackageLinksPage.types';
import { usePackageLinks } from './usePackageLinks';
import { ActivationContext } from '../../../activation/Activation';
import { useGetSharedFunctionality } from '../../../api/lending/lapiHooks';
import { TPricingType } from '../../../Buyer/PayLinkLanding/utils/PayLinkLanding.types';
import { HideFor } from '../../../components/ConditionalDisplay/ConditionalDisplay';
import { LockedButton } from '../../../components/LockedButton/LockedButton';
import { getIwocaPayMeUrl } from '../../../components/PayMeLink/getIwocaPayMeUrl';
import { PayMeLink } from '../../../components/PayMeLink/PayMeLink';
import { useSellerHandle } from '../../../hooks/useSellerHandle.hook';
import { useStateKey } from '../../../hooks/useStateKey.hook';
import { createToast } from '../../../store/IwToast';
import {
  PayLinkInputs,
  PricingTermOption,
} from '../../ManageAccount/components/PayLaterPricingSettings/PayLaterPricingSettings';
import { CheckoutPreview } from '../components/CheckoutPreview/CheckoutPreview';
import { PricingOptionDescriptions } from '../components/PricingOptionDescriptions/PricingOptionDescriptions';
import {
  Column,
  VerticalSplitLayout,
} from '../components/VerticalSplitLayout/VerticalSplitLayout';
import { useProduct } from '../hooks/useProduct';
import payLinksPageStyles from '../PayLinks.module.css';
import {
  DEFAULT_12M_PRICING_OPTION,
  DEFAULT_30D_PRICING_OPTION,
} from '../PayLinksNavigation';
import { usePreview } from '../usePreview';
import { validateForm } from '../utils/validateForm';

export const PackageLinkPage = () => {
  const { sellerHandle } = useSellerHandle();
  const { packageLinks, fetchPackageLinks } = usePackageLinks();
  const { previewValues, setPreviewValues } = usePreview();

  const activePackageLinks = packageLinks.filter(
    (packageLink: PackageLink) => !packageLink.deleted_at,
  );

  if (!sellerHandle) return null;

  return (
    <div className={payLinksPageStyles.PayLinksPage}>
      <VerticalSplitLayout>
        <Column>
          <LeftSide
            previewValues={previewValues}
            setPreviewValues={setPreviewValues}
            fetchPackageLinks={fetchPackageLinks}
            sellerHandle={sellerHandle}
          />
        </Column>
        <Column>
          {previewValues.pricing_3m ? (
            <CheckoutPreview
              sellerName={previewValues.sellerName}
              amount={previewValues.amount}
              reference={previewValues.reference}
              pricingPromotions={{
                THREE_MONTHS: previewValues.pricing_3m.promotions,
                TWELVE_MONTHS: previewValues.pricing_12m?.promotions,
                THIRTY_DAYS: previewValues.pricing_30d?.promotions,
              }}
              representativeInterest={{
                THREE_MONTHS: previewValues.pricing_3m.representativeInterest,
                TWELVE_MONTHS:
                  previewValues.pricing_12m?.representativeInterest,
                THIRTY_DAYS: previewValues.pricing_30d?.representativeInterest,
              }}
            />
          ) : (
            <div>Loading preview...</div>
          )}
        </Column>
      </VerticalSplitLayout>
      <div className={styles.horizontalContainer}>
        <h2 className={payLinksPageStyles.subTitle}>
          Your existing package links
        </h2>
        <p className={payLinksPageStyles.description}>
          We save your package links so you can easily reshare them with
          customers.
        </p>
        <ExistingPackageLinksTable
          existingPackageLinks={activePackageLinks}
          sellerHandle={sellerHandle}
        />
      </div>
    </div>
  );
};

const LeftSide = ({
  previewValues,
  setPreviewValues,
  fetchPackageLinks,
  sellerHandle,
}: {
  previewValues: ReturnType<typeof usePreview>['previewValues'];
  setPreviewValues: (name: string, value: unknown) => void;
  fetchPackageLinks: () => void;
  sellerHandle?: string;
}) => {
  type Tpricing = 'SELLER_FREE_BUYER_PAYS' | 'SELLER_PAYS_BUYER_FREE';
  const { functionality } = useGetSharedFunctionality();
  const formRef = useRef<HTMLFormElement>(null);
  const [payLink, setPayLink] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [touched, setTouched] = useState<{ [key: string]: string }>({});

  const [amount, setAmount] = useState('');
  const [reference, setReference] = useState('');
  const [packageLinkName, setPackageLinkName] = useState<string>('');
  const [threeMonthPricing, setThreeMonthPricing] = useState<Tpricing>();
  const [twelveMonthPricing, setTwelveMonthPricing] = useState<Tpricing>();
  const [thirtyDayPricing, setThirtyDayPricing] = useState<Tpricing>();

  const { productEnabled: is12MonthsEnabled } = useProduct('TWELVE_MONTHS');
  const { productEnabled: is30DaysEnabled } = useProduct('THIRTY_DAYS');

  useEffect(() => {
    setThreeMonthPricing(previewValues.pricing_3m?.type as Tpricing);
    setTwelveMonthPricing(
      (previewValues.pricing_12m?.type as Tpricing) ||
        DEFAULT_12M_PRICING_OPTION,
    );
    setThirtyDayPricing(
      (previewValues.pricing_30d?.type as Tpricing) ||
        DEFAULT_30D_PRICING_OPTION,
    );
  }, [
    previewValues.pricing_3m,
    previewValues.pricing_12m,
    previewValues.pricing_30d,
  ]);

  const { stateKey } = useStateKey();
  const { isOnboarded } = useContext(ActivationContext);

  const formSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError('Please provide a pay link amount')
      .min(0, 'Please provide a pay link amount')
      .required(),
    reference: Yup.string()
      .max(50, 'Limit 50 characters reached')
      .required('Please provide a pay link reference'),
    packageLinkName: Yup.string().required(
      'Please provide a package link name',
    ),
  });

  const submit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const data = await validateForm(ev.currentTarget, formSchema, setErrors);

    if (data && previewValues.pricing_3m) {
      const body = {
        reference: data.reference,
        amount: data.amount,
        name: data.packageLinkName,
        product_pricing: {
          THREE_MONTHS: previewValues.pricing_3m.type as TPricingType,
          ...(previewValues.pricing_12m && {
            TWELVE_MONTHS: previewValues.pricing_12m.type as TPricingType,
          }),
          ...(previewValues.pricing_30d && {
            THIRTY_DAYS: previewValues.pricing_30d.type as TPricingType,
          }),
        },
      };

      try {
        const response = await fetchPostSellerPayLinkPackage({
          stateKey: stateKey!,
          body: body,
        });
        const payLinkPackageId = response.data.id;
        setPayLink(getIwocaPayMeUrl(sellerHandle!, payLinkPackageId));
        fetchPackageLinks();
      } catch (e) {
        createToast(ERROR_MESSAGE);
      } finally {
        resetForm();
      }
    }
  };

  const resetForm = () => {
    setAmount('');
    setReference('');
    setPackageLinkName('');
    setErrors({});
  };

  return (
    <div className="mt-2xl grid grid-cols-1 gap-xl">
      <div>
        <h1 className="mb-0 mt-0 text-2xl font-bold text-primary-10">
          A reusable pay link for a specific amount
        </h1>
        <p className="mb-0 mt-s text-m font-reg text-primary-10">
          Take multiple payments for a set amount. Perfect for a specific
          product or service.
        </p>
      </div>

      <div>
        <h2 className="mb-0 mt-0 text-l font-bold text-primary-10">
          Choose who pays for Pay Later via Package links
        </h2>
        <p className="mb-0 mt-s text-m font-reg text-primary-10">
          These settings will apply only to this pay link.
        </p>
      </div>

      <form
        ref={formRef}
        aria-label="packagePayLinkForm"
        className="grid grid-cols-1 gap-xl"
        onSubmit={submit}
      >
        <PayLinkInputs
          amount={amount}
          setAmount={setAmount}
          reference={reference}
          setReference={setReference}
          validateForm={() =>
            validateForm(formRef.current!, formSchema, setErrors)
          }
          setPreviewValues={setPreviewValues}
          setTouched={setTouched}
          errors={errors}
          touched={touched}
        />

        <Input
          wrapperClassName="mb-m"
          label="Name your Package link"
          description="These settings will apply only to this pay link."
          name="packageLinkName"
          value={packageLinkName}
          onChange={(ev) => {
            setPackageLinkName(ev.target.value);
            void validateForm(formRef.current!, formSchema, setErrors);
            setTouched({ ...touched, packageLinkName: 'true' });
          }}
          placeholder="Paylink name..."
          error={touched.packageLinkName && errors.packageLinkName}
        />

        <HideFor contributor fallback={<PricingOptionDescriptions />}>
          <PricingTermOption
            productKey={'THREE_MONTHS'}
            pricingType={threeMonthPricing}
            onChange={(pricingType) => {
              setThreeMonthPricing(pricingType);
              setPreviewValues('pricing_3m', pricingType);
            }}
            enabled={true}
          />

          <PricingTermOption
            productKey={'TWELVE_MONTHS'}
            pricingType={twelveMonthPricing}
            onChange={(pricingType) => {
              setTwelveMonthPricing(pricingType);
              setPreviewValues('pricing_12m', pricingType);
            }}
            onToggle={(enabled) => {
              const terms = enabled ? 'SELLER_FREE_BUYER_PAYS' : null;
              setPreviewValues('pricing_12m', terms);
            }}
            enabled={is12MonthsEnabled}
          />

          {functionality?.is_30_days_enabled && (
            <PricingTermOption
              productKey={'THIRTY_DAYS'}
              pricingType={thirtyDayPricing}
              onChange={(pricingType) => {
                setThirtyDayPricing(pricingType);
                setPreviewValues('pricing_30d', pricingType);
              }}
              onToggle={(enabled) => {
                const terms = enabled ? 'SELLER_FREE_BUYER_PAYS' : null;
                setPreviewValues('pricing_30d', terms);
              }}
              enabled={is30DaysEnabled}
            />
          )}
        </HideFor>

        <div className="flex flex-col gap-m">
          {payLink && <PayMeLink payMeUrl={payLink} />}
          {isOnboarded ? (
            <Button
              disabled={
                Object.keys(errors).length > 0 ||
                Object.keys(touched).length !== 3
              }
              className="w-fit"
            >
              Generate package link
            </Button>
          ) : (
            <LockedButton />
          )}
        </div>
      </form>
    </div>
  );
};

const ERROR_MESSAGE =
  'Error creating pay link package. Please refresh the page and try again.';
