import {
  useGetSellerByHandle,
  useGetSellerEnabledProducts,
  usePutSellerEnabledProducts,
} from '../../../api/lending/lapiHooks';
import { useSellerHandle } from '../../../hooks/useSellerHandle.hook';
import { useSellerPricing } from '../../../hooks/useSellerPricing';
import { TSellerProduct } from '../utils/Paylinks.types';

export const useProduct = (
  product: 'THREE_MONTHS' | 'TWELVE_MONTHS' | 'THIRTY_DAYS',
) => {
  const { sellerHandle } = useSellerHandle();
  const { fetchSeller } = useGetSellerByHandle(sellerHandle || undefined);
  const { fetchSellerPricing } = useSellerPricing();
  const { sellerEnabledProducts, loadingSellerEnabledProducts } =
    useGetSellerEnabledProducts();
  const { putSellerEnabledProducts } = usePutSellerEnabledProducts();

  const enabledProducts = sellerEnabledProducts?.enabled_products || [];
  const productEnabled = enabledProducts.includes(product);

  const toggleProduct = async () => {
    const newEnabledProducts: TSellerProduct[] = productEnabled
      ? enabledProducts.filter((enabledProduct) => enabledProduct !== product)
      : enabledProducts.concat([product]);
    await putSellerEnabledProducts({
      enabled_products: newEnabledProducts,
    });
    await fetchSeller();
    await fetchSellerPricing();
  };

  return {
    productEnabled,
    toggleProduct,
    isLoading: loadingSellerEnabledProducts,
  };
};
