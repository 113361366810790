import { useCallback, useEffect, useState } from 'react';

import { fetchPutSellerPricing } from '@iwoca/lapi-client/iwocapay';
import { AlertBox, Button, Icon, Input, Radio, Switch } from '@iwoca/orion';

import { useGetSharedFunctionality } from '../../../../api/lending/lapiHooks';
import { TPricingType } from '../../../../Buyer/PayLinkLanding/utils/PayLinkLanding.types';
import { useSellerPricing } from '../../../../hooks/useSellerPricing';
import { useStateKey } from '../../../../hooks/useStateKey.hook';
import { createToast } from '../../../../store/IwToast';
import { useProduct } from '../../../PayLinks/hooks/useProduct';
import { ConfirmationModal } from '../../../PayLinks/ReusablePaylinkPage/ReusablePaylinkPage';

const InfoBox = ({ children }: { children: JSX.Element }) => {
  return (
    <AlertBox variant="cancel">
      <div className="grid-col grid grid-cols-[min-content,1fr] gap-s">
        <Icon fill="#437089" icon="infoCircleOutline" height={20} width={20} />
        <div className="font-reg">{children}</div>
      </div>
    </AlertBox>
  );
};

const TermInfo = ({
  productKey,
  pricingType,
  termEnabled,
}: {
  productKey: 'THREE_MONTHS' | 'TWELVE_MONTHS' | 'THIRTY_DAYS';
  pricingType?: TPricingType;
  termEnabled: boolean;
}): JSX.Element => {
  const { sellerPricing } = useSellerPricing();
  if (sellerPricing === null) return <></>;

  const termDetails = sellerPricing!.product_pricing_options[productKey]?.find(
    ({ pricing_type }) => pricing_type === pricingType,
  );

  const numberOfPayments = productKey === 'THREE_MONTHS' ? '3' : '12';

  if (['THREE_MONTHS', 'TWELVE_MONTHS'].includes(productKey)) {
    if (!termDetails || !termEnabled)
      return (
        <>
          Turn on {numberOfPayments} months Pay Later to offer your customers
          the ability to spread the cost over {numberOfPayments} months.
        </>
      );

    if (pricingType === 'SELLER_PAYS_BUYER_FREE') {
      return (
        <>
          Your customers can Pay in {numberOfPayments} for free -{' '}
          <strong>
            you pay a fixed {termDetails.seller_fee_percentage}% fee of the
            total transaction value.
          </strong>{' '}
        </>
      );
    }

    if (pricingType === 'SELLER_FREE_BUYER_PAYS') {
      return (
        <>
          Your customers make{' '}
          <strong>
            {numberOfPayments} monthly payments including interest
          </strong>{' '}
          at a representative rate of{' '}
          {formatPricing(termDetails.buyer.representative_interest)}% - you
          don’t pay a penny.
        </>
      );
    }
  }
  if (productKey === 'THIRTY_DAYS') {
    if (!termDetails || !termEnabled)
      return (
        <>Give your customers the option to pay the full amount in 30 days</>
      );

    if (pricingType === 'SELLER_PAYS_BUYER_FREE') {
      return (
        <>
          Your customers{' '}
          <strong>pay the full amount in 30 days interest free</strong> - you
          pay a fixed {termDetails.seller_fee_percentage}% fee
        </>
      );
    }

    if (pricingType === 'SELLER_FREE_BUYER_PAYS') {
      return (
        <>
          Your customers <strong>pay the full amount in 30 days</strong>{' '}
          including interest at a representative rate of{' '}
          {formatPricing(termDetails.buyer.representative_interest)}% - you
          don't pay a penny
        </>
      );
    }
  }

  return <>Unknown</>;
};

export const formatPricing = (value: number) => {
  return parseFloat((value * 100).toFixed(2));
};

export const PayLinkInputs = ({
  amount,
  setAmount,
  reference,
  setReference,
  setPreviewValues,
  validateForm,
  setTouched,
  errors,
  touched,
}: {
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  reference: string;
  setReference: React.Dispatch<React.SetStateAction<string>>;
  setPreviewValues: (name: string, value: unknown) => void;
  validateForm: () => void;
  setTouched: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  errors: { [key: string]: string };
  touched: { [key: string]: string };
}) => {
  return (
    <div className="mb-xl grid grid-cols-2 grid-rows-1 gap-l sm:grid-cols-1 sm:grid-rows-2">
      <Input
        label="Amount"
        description="Your customer can’t change this."
        name="amount"
        type="number"
        value={amount}
        onChange={(ev) => {
          setAmount(ev.target.value);
          setPreviewValues('amount', ev.target.value);
          validateForm();
          setTouched({ ...touched, amount: 'true' });
        }}
        placeholder="Enter amount..."
        error={touched.amount && errors.amount}
      />
      <Input
        label="Reference"
        description="Your customer can’t change this."
        name="reference"
        value={reference}
        onChange={(ev) => {
          setReference(ev.target.value);
          setPreviewValues('reference', ev.target.value);
          validateForm();
          setTouched({ ...touched, reference: 'true' });
        }}
        placeholder="Enter reference..."
        error={touched.reference && errors.reference}
      />
    </div>
  );
};

export const PricingTermOption = ({
  productKey,
  pricingType,
  enabled = false,
  onChange,
  onToggle,
}: {
  productKey: 'THREE_MONTHS' | 'TWELVE_MONTHS' | 'THIRTY_DAYS';
  pricingType?: TPricingType;
  enabled: boolean;
  onChange: (pricingType: TPricingType) => void;
  onToggle?: (enabled: boolean) => void;
}) => {
  const [isEnabled, setIsEnabled] = useState(enabled);
  useEffect(() => setIsEnabled(enabled), [enabled]);

  const title = (
    productKey: 'THREE_MONTHS' | 'TWELVE_MONTHS' | 'THIRTY_DAYS',
  ) => {
    switch (productKey) {
      case 'THREE_MONTHS':
        return 'Pay in 3';
      case 'TWELVE_MONTHS':
        return 'Pay in 12';
      case 'THIRTY_DAYS':
        return 'Pay in 30 days';
    }
  };

  return (
    <div className="grid grid-cols-1 gap-m">
      <div className="grid grid-cols-[max-content,1fr] items-center">
        <h3 className="mb-0 mt-0 text-m">{title(productKey)}</h3>
        {onToggle && (
          <div className="flex items-center justify-end gap-m">
            {isEnabled ? 'On' : 'Off'}
            <Switch
              checked={isEnabled}
              onClick={() => {
                const newIsEnabled = !isEnabled;
                setIsEnabled((prev) => newIsEnabled);
                onToggle?.(newIsEnabled);
              }}
            />
          </div>
        )}
      </div>

      {isEnabled && (
        <div
          className="col-auto grid w-fit grid-cols-2 sm:grid-cols-1 sm:gap-l"
          data-testid={`pricing_${productKey}`}
        >
          <Radio
            checked={pricingType === 'SELLER_FREE_BUYER_PAYS'}
            name={`pricing_${productKey}`}
            value="SELLER_FREE_BUYER_PAYS"
            onChange={() => onChange('SELLER_FREE_BUYER_PAYS')}
            label="Free for you"
          />
          <Radio
            checked={pricingType === 'SELLER_PAYS_BUYER_FREE'}
            name={`pricing_${productKey}`}
            value="SELLER_PAYS_BUYER_FREE"
            onChange={() => onChange('SELLER_PAYS_BUYER_FREE')}
            label="Free for your customers"
          />
        </div>
      )}

      <InfoBox>
        <TermInfo
          productKey={productKey}
          pricingType={pricingType}
          termEnabled={isEnabled}
        />
      </InfoBox>
    </div>
  );
};

type TPricingTermOption = {
  enabled: boolean;
  pricingType: TPricingType;
};

type TPricingOptions = {
  THREE_MONTHS: TPricingTermOption;
  TWELVE_MONTHS: TPricingTermOption;
  THIRTY_DAYS: TPricingTermOption;
};

export const PayLaterPricingSettings = () => {
  const { functionality } = useGetSharedFunctionality();
  const [chosenPricing, setChosenPricing] = useState<TPricingOptions | null>(
    null,
  );

  const [confirmationModalState, setShowConfirmationModalState] = useState<
    'HIDDEN' | 'SHOWN' | 'PENDING'
  >('HIDDEN');
  const { sellerPricing, fetchSellerPricing } = useSellerPricing();
  const { toggleProduct: toggle12Months, productEnabled: is12MonthsEnabled } =
    useProduct('TWELVE_MONTHS');
  const { toggleProduct: toggle30Days, productEnabled: is30DaysEnabled } =
    useProduct('THIRTY_DAYS');

  const { stateKey } = useStateKey();

  const savedPricingToChosen = useCallback(() => {
    const enabledThreeMonths =
      sellerPricing?.product_pricing_options.THREE_MONTHS.find(
        ({ is_enabled }) => is_enabled,
      );
    const enabledTwelveMonths =
      sellerPricing?.product_pricing_options.TWELVE_MONTHS?.find(
        ({ is_enabled }) => is_enabled,
      );
    const enabledThirtyDays =
      sellerPricing?.product_pricing_options.THIRTY_DAYS?.find(
        ({ is_enabled }) => is_enabled,
      );

    return {
      THREE_MONTHS: {
        enabled: !!enabledThreeMonths,
        pricingType:
          enabledThreeMonths?.pricing_type || 'SELLER_FREE_BUYER_PAYS',
      },
      TWELVE_MONTHS: {
        enabled: !!enabledTwelveMonths,
        pricingType:
          enabledTwelveMonths?.pricing_type || 'SELLER_FREE_BUYER_PAYS',
      },
      THIRTY_DAYS: {
        enabled: !!enabledThirtyDays,
        pricingType:
          enabledThirtyDays?.pricing_type || 'SELLER_FREE_BUYER_PAYS',
      },
    };
  }, [sellerPricing]);

  const resetToDefaults = useCallback(() => {
    const savedChosenPricing = savedPricingToChosen();
    setChosenPricing(savedChosenPricing);
  }, [savedPricingToChosen]);

  useEffect(() => {
    if (sellerPricing) {
      resetToDefaults();
    }
  }, [sellerPricing, resetToDefaults]);

  // if (sellerPricing !== null && chosenPricing === null) resetToDefaults();
  if (chosenPricing === null) return <></>;

  const switchPricing = ({
    pricingTerm,
    productKey,
  }: {
    pricingTerm: TPricingType;
    productKey: 'THREE_MONTHS' | 'TWELVE_MONTHS' | 'THIRTY_DAYS';
  }) => {
    if (chosenPricing === null) return;

    setChosenPricing({
      ...chosenPricing,
      [productKey]: {
        ...chosenPricing[productKey],
        enabled: chosenPricing[productKey].enabled,
        pricingType: pricingTerm,
      },
    });
  };

  const hasChanged = () => {
    /*
      Note: We compare the terms rather than chosenPricing vs savedPricingToChosen()
      as we don't want to detect a change in the enabled state
     */
    const threeMonthChanged =
      chosenPricing.THREE_MONTHS.pricingType !==
      savedPricingToChosen().THREE_MONTHS.pricingType;
    const twelveMonthChanged =
      chosenPricing.TWELVE_MONTHS.pricingType !==
      savedPricingToChosen().TWELVE_MONTHS.pricingType;
    const thirtyDaysChanged =
      chosenPricing.THIRTY_DAYS.pricingType !==
      savedPricingToChosen().THIRTY_DAYS.pricingType;

    return threeMonthChanged || twelveMonthChanged || thirtyDaysChanged;
  };

  return (
    <>
      <div className="mt-xl grid grid-cols-1 gap-xl">
        <PricingTermOption
          productKey={'THREE_MONTHS'}
          pricingType={chosenPricing['THREE_MONTHS'].pricingType}
          enabled={true}
          onChange={(pricingType) =>
            switchPricing({
              pricingTerm: pricingType,
              productKey: 'THREE_MONTHS',
            })
          }
        />
        <PricingTermOption
          productKey={'TWELVE_MONTHS'}
          pricingType={chosenPricing['TWELVE_MONTHS'].pricingType}
          onChange={(pricingType) =>
            switchPricing({
              pricingTerm: pricingType,
              productKey: 'TWELVE_MONTHS',
            })
          }
          enabled={is12MonthsEnabled}
          onToggle={async (enabled) => {
            await toggle12Months();
            createToast(`Switched ${enabled ? 'off' : 'on'} Pay in 12`);
            await fetchSellerPricing();
          }}
        />
        {functionality?.is_30_days_enabled && (
          <PricingTermOption
            productKey={'THIRTY_DAYS'}
            pricingType={chosenPricing['THIRTY_DAYS'].pricingType}
            onChange={(pricingType) =>
              switchPricing({
                pricingTerm: pricingType,
                productKey: 'THIRTY_DAYS',
              })
            }
            enabled={is30DaysEnabled}
            onToggle={async (enabled) => {
              await toggle30Days();
              createToast(`Switched ${enabled ? 'off' : 'on'} Pay in 30 days`);
              await fetchSellerPricing();
            }}
          />
        )}
        {hasChanged() && (
          <div className="mt-xl grid w-fit grid-cols-2 gap-s sm:w-full">
            <Button
              type="submit"
              onClick={() => setShowConfirmationModalState('SHOWN')}
            >
              Save changes
            </Button>
            <Button variant="secondary" onClick={() => resetToDefaults()}>
              Revert changes
            </Button>
          </div>
        )}
      </div>

      <ConfirmationModal
        isOpen={confirmationModalState !== 'HIDDEN'}
        isSubmitting={confirmationModalState === 'PENDING'}
        onConfirm={async () => {
          setShowConfirmationModalState('PENDING');
          const values = {
            THREE_MONTHS: chosenPricing.THREE_MONTHS.pricingType,
            ...(chosenPricing.TWELVE_MONTHS.enabled && {
              TWELVE_MONTHS: chosenPricing.TWELVE_MONTHS.pricingType,
            }),
            ...(chosenPricing.THIRTY_DAYS.enabled && {
              THIRTY_DAYS: chosenPricing.THIRTY_DAYS.pricingType,
            }),
          };

          try {
            await fetchPutSellerPricing({
              stateKey: stateKey!,
              body: {
                active_product_pricing: values,
              },
            });

            await fetchSellerPricing();
            setShowConfirmationModalState('HIDDEN');
          } catch (e) {
            createToast('Unable to save changes. Please try again.');
          }
        }}
        onCancel={() => {
          setShowConfirmationModalState('HIDDEN');
          resetToDefaults();
        }}
      />
    </>
  );
};
