import { useParams } from 'react-router';

import { MagentoStep1, MagentoStep2 } from './MagentoSteps';
import { useGetIwocapayIntegrationInterest } from '../../../../api/lending/lapiHooks';
import externalLinkSVG from '../../../../assets/externalLink.svg';
import tickSVG from '../../assets/tick.svg';
import { useGetIntegrationStatus } from '../../hooks/useGetIntegrationStatus.hook';
import { Magento } from '../../Integrations';
import bodyStyles from '../IntegrationBody.module.css';
import { IntegrationsTitle } from '../IntegrationsTitle/IntegrationsTitle';
import { RegisterInterestButton } from '../RegisterInterestButton/RegisterInterestButton';

export function MagentoBody() {
  const { integrationParam } = useParams();

  const { integrationStatus } = useGetIntegrationStatus({
    integrationId: Magento.id,
  });
  const isConnected = integrationStatus === 'CONNECTED';

  const { iwocapayIntegrationInterest, loadingIwocapayIntegrationInterest } =
    useGetIwocapayIntegrationInterest();

  const interestRegistered =
    Magento.id && iwocapayIntegrationInterest?.includes(Magento.id);

  const userHasInteracted = isConnected || interestRegistered;

  if (integrationParam !== Magento.id) {
    return null;
  }
  if (loadingIwocapayIntegrationInterest) {
    return null;
  }
  return (
    <div className={bodyStyles.container}>
      <div className={bodyStyles.header}>
        <IntegrationsTitle integration={Magento} />
        <div className={bodyStyles.connectButtonWrapper}>
          {userHasInteracted && (
            <div className={bodyStyles.requestSent}>
              <img
                src={tickSVG}
                alt="Tick Icon"
                className={bodyStyles.tickSVG}
              />
              {isConnected ? 'Connected' : 'Request sent'}
            </div>
          )}
          <RegisterInterestButton integrationName={Magento.id} />
        </div>
      </div>
      <div className={bodyStyles.body}>
        <div>
          {!userHasInteracted && (
            <p>
              Build multi-channel commerce experiences for B2B and B2C customers
              on a single platform. Endlessly flexible, extensible and scalable.
              <br />
              <br />
              <b>
                When you connect iwocaPay to {Magento.company_name} it'll
                automatically:
              </b>
              <ul>
                <li>
                  add iwocaPay as a payment method on your{' '}
                  {Magento.company_name} checkout
                </li>
                <li>
                  reconcile all iwocaPay payments with your{' '}
                  {Magento.company_name} store data.
                </li>
              </ul>
            </p>
          )}
          {userHasInteracted && (
            <div>
              <p>
                <b>
                  We’ve got your request to access the {Magento.company_name}{' '}
                  plugin.
                </b>
                <br />
                Not long now till you’ve added iwocaPay to your store checkout.
                <br />
                <br />
                Here’s what happens next:
              </p>
              <div className={bodyStyles.stepContainer}>
                <MagentoStep1 />
                <MagentoStep2 />
              </div>
            </div>
          )}
          <div>
            <a
              href="https://ecommerce-api.iwoca.com/guides/plugin-solution/magento/"
              target="_blank"
              rel="noreferrer"
              className={bodyStyles.faqLink}
            >
              {Magento.company_name} FAQs
              <img
                src={externalLinkSVG}
                alt=""
                className={bodyStyles.externalLinkIcon}
              />
            </a>
          </div>
        </div>
      </div>
      <div className={bodyStyles.footer}>
        <RegisterInterestButton integrationName={Magento.id} />
      </div>
    </div>
  );
}
