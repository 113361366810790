import {
  fetchPostThreatmetrixEvent,
  fetchPutThreatmetrixEvent,
} from '@iwoca/lapi-client/edge';

import { useOnce } from '../hooks/useOnce';
import { useStateKey } from '../hooks/useStateKey.hook';

export const THREATMETRIX_ORG_ID = '8u0q6ob5';
/**
 * Signup pages = 1
 * Account pages = 2
 * iwocaPay pages = 3
 **/
export const IWOCAPAY_PAGES_IDENTIFIER = 3;

async function profileThreat(stateKey: string) {
  threatmetrixSetup();

  const response = await fetchPostThreatmetrixEvent({
    stateKey: stateKey,
    body: { data: { page_id: IWOCAPAY_PAGES_IDENTIFIER } },
  });
  const sessionID = response.data.tm_session_id;

  // @ts-expect-error Threatmatrix is set up by threatmetrixSetup() function
  if (window.threatmetrix) {
    // @ts-expect-error We know it's defined now
    window.threatmetrix.profile(
      'h.online-metrix.net',
      THREATMETRIX_ORG_ID,
      sessionID,
    );

    await fetchPutThreatmetrixEvent({
      stateKey: stateKey,
      tmSessionId: sessionID,
      body: { data: { is_profiled: true } },
    });
  }

  setupThreatmetrixTagging(sessionID);
}

function threatmetrixSetup() {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = `https://static.iwoca.com/js/435ebd53-dd09-4a6e-8e26-651f497d92d1.js`;
  document.head.appendChild(s);
}

function setupThreatmetrixTagging(sessionID: string) {
  const iframe = document.createElement('iframe');
  iframe.title = 'Threatmetrix';
  iframe.src = `https://h.online-metrix.net/fp/tags?org_id=${THREATMETRIX_ORG_ID}&session_id=${sessionID}`;
  iframe.style.position = 'absolute';
  iframe.style.border = '0';
  iframe.style.top = '-5000px';
  iframe.style.height = '1px';
  iframe.style.width = '1px';
  document.body.appendChild(iframe);
}

export function useThreatMetrix() {
  const { stateKey } = useStateKey();
  useOnce({
    fn: () => profileThreat(stateKey!),
    conditionFn: Boolean(stateKey),
  });
}
